import React from 'react';
import ReactDOM from 'react-dom/client';
import { THEME, TonConnectUIProvider } from '@tonconnect/ui-react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Buffer } from 'buffer';
import App from './App';
import './font.css';

import { store, persistor } from './store/store';

window.Buffer = Buffer;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <TonConnectUIProvider
      manifestUrl='https://durak.live/static/tonconnect-manifest.json'
      uiPreferences={{ theme: THEME.DARK }}
    >
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </TonConnectUIProvider>
  </React.StrictMode>
);

// reportWebVitals();
